import * as React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

interface MapProps {
    center: any;
    zoom: number;
    markerPosition: any;
}

const Map: React.FC<MapProps> = ({ center, zoom = 17, markerPosition }) => {

    return (
        <MapContainer center={center} zoom={zoom} scrollWheelZoom={false} style={{ width: "100%", height: "100%" }}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=03a79c85-2b4f-44cc-8560-fd4cb4cf03a8"
            />
            <Marker position={markerPosition}>
                <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
            </Marker>
        </MapContainer>
    )
};

export default Map;
