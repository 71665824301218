import * as React from "react";

import { Box, ImageList, ImageListItem, ImageListItemBar, Theme, useMediaQuery } from "@mui/material";
import projectData from "../../data/projects.json";

import { Project } from "../../models/models";
import { useNavigate } from "react-router";

const style = {
    projcetListItem: {
        overflow: "hidden",
        cursor: "pointer",
        opacity: "0.95",
    },
    projectImage: {
        border: "1px solid #A1A1A1",
        transition: "transform .6s",
        filter: "opacity(0.9)",
    },
    projectImageActive: {
        transform: "scale(1.1)",
    },
    projectItemBar: {
        width: "100%",
        height: "100%",
        textAlign: "center",
        color: "#333",
        background: "rgba(255, 255, 255, 0.7)",
    }
}
const ProjectList = () => {

    const navigate = useNavigate();
    const projects: Project[] | any[] = projectData;

    const [activeProject, setActiveProject] = React.useState<string | undefined>(undefined);

    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

    const onNavigate = (project: Project): void => {
        // router.push(`/projecten/${project.ident}`);
        console.log("project", project);
        navigate(`/projecten/${project.ident}`);
    };

    const srcset = (image: string, size: number, rows = 1, cols = 1) => {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${
                size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
        };
    };

    return (
        <Box sx={{ pl: 4, pr: 4, mb: 10, marginTop: "150px" }}>
            <ImageList
                variant="quilted"
                cols={4}
                gap={30}
            >
                {projects.map((project: Project, index) => (
                    <ImageListItem
                        key={index}
                        sx={style.projcetListItem}
                        cols={!matches ? 4 : (project.cols || 1)}
                        rows={project.rows || 1}
                        onClick={() => onNavigate(project)}
                        onMouseOver={() => setActiveProject(project.ident)}
                        onMouseLeave={() => setActiveProject(undefined)}
                    >
                        <img
                            {...srcset(project.image, 121, project.rows, project.cols)}
                            alt={project.title}
                            loading="lazy"
                            style={{
                                ...style.projectImage,
                                transform: activeProject === project.ident ? "scale(1.1)" : "scale(1)"
                            }}
                        />
                        {activeProject === project.ident && (
                            <ImageListItemBar
                                sx={style.projectItemBar}
                                title={project.title}
                            />
                        )}
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    )
}

export default ProjectList;
