import * as React from "react";
import { Route, Routes } from "react-router-dom";

import ProjectList from "./components/project/ProjectList";
import Contact from "./components/contact/Contact";
import ProjectDetail from "./components/project/ProjectDetail";
import HeroBanner from "./components/common/HeroBanner";
import Footer from "./components/common/Footer";
import Interior from "./components/interior/Interior";

const AppRouter = () => {

    return (
            <Routes>
                <Route path="projecten/:ident"
                       element={
                            <React.Fragment>
                                <ProjectDetail />
                                <Footer />
                            </React.Fragment>
                       }
                />

                <Route path="projecten"
                       element={
                           <React.Fragment>
                               <ProjectList />
                               <Footer />
                           </React.Fragment>
                       }
                />

                <Route path="/interieur"
                       element={
                           <React.Fragment>
                               <Interior />
                               <Footer />
                           </React.Fragment>
                       }
                />

                <Route path="/onderhoud"
                       element={
                           <React.Fragment>
                               <Interior />
                               <Footer />
                           </React.Fragment>
                       }
                />

                <Route path="/contact"
                       element={
                           <React.Fragment>
                               <Contact />
                               <Footer />
                           </React.Fragment>
                       }
                />

                <Route path="/sitemap"
                       element={
                           <React.Fragment>
                               <Contact />
                               <Footer />
                           </React.Fragment>
                       }
                />

                <Route path="/"
                       element={
                           <HeroBanner />
                       }
                />

                <Route path="*" element={<HeroBanner />} />
            </Routes>
    );
};

export default AppRouter;
