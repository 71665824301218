import * as React from "react";
import { Box, Container, Grid } from "@mui/material";
import { NavLink } from "react-router-dom";

const style = {
    footer: {
        width: "100%",
        background: "#292929",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 8,
        marginTop: -2,
        zIndex: 99,
        position: "relative",
    },
    footerComponent: {
        display: "flex",
        alignItems: "center",
        color: "#8c8c8c",
        fontSize: 13,
        paddingTop: 2,
        paddingBottom: 2,
    }
};

const Footer: React.FC = () => {
    return (
        <Box sx={style.footer}>
            <Container fixed={true}>
                <Grid container={true}>
                    <Grid item={true} xs={12} md={4} sx={{ ...style.footerComponent, justifyContent: { xs: "center", md: "flex-start" }}}>
                        <NavLink to="/">
                            <img
                                src="/assets/images/cooper-projectrealisatie-logo.png"
                                alt="Cooper projectrealisatie"
                                width={125}
                                height={50}
                            />
                        </NavLink>
                    </Grid>
                    <Grid item={true} xs={12} md={4} sx={{ ...style.footerComponent, justifyContent: "center", textAlign: "center" }}>
                        © Copyright 2018 Cooper Projectrealisatie
                    </Grid>
                    <Grid item={true} xs={12} md={4} sx={{ ...style.footerComponent, justifyContent: { xs: "center", md: "flex-end" }}}>
                        T. 020 664 33 70<br />
                        E. info@cooper-project.nl
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
};

export default Footer;
