import * as React from "react";

import { Backdrop, Container, IconButton } from "@mui/material";
import { NavLink } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

const style = {
    header: {
        height: 85,
        backgroundColor: "#f5f5f5",
        paddingTop: 2,
        paddingBottom: 2,
    },
    menuBar: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: 20,
        fontWeight: "bold",
        padding: 24,
        cursor: "pointer",
    },
}
const Header: React.FC = () => {

    const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);
    
    return (
        <React.Fragment>
            <AppBar component="nav" sx={style.header}>
                <Toolbar>
                    <NavLink to="/">
                        <img
                            src="/assets/images/cooper-projectrealisatie-logo.png"
                            alt="Cooper projectrealisatie"
                            width={130}
                        />
                    </NavLink>

                    <Box sx={style.menuBar}>
                        <IconButton onClick={() => setMenuIsOpen(true)}>
                            <MenuIcon sx={{ fontSize: 50, color: "#525252" }} />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>

            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "rgba(39,39,39,.95)" }}
                transitionDuration={300}
                open={menuIsOpen}
                onClick={() => setMenuIsOpen(false)}
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <NavLink to={`/`} style={{ ...style.link }}>
                        HOME
                    </NavLink>
                    <NavLink to={`/projecten`} style={{ ...style.link }}>
                        PROJECTEN
                    </NavLink>
                    <NavLink to={`/interieur`} style={{ ...style.link }}>
                        INTERIEUR
                    </NavLink>
                    <NavLink to={`/onderhoud`} style={{ ...style.link }}>
                        ONDERHOUD
                    </NavLink>
                    <NavLink to={`/contact`} style={{ ...style.link }}>
                        CONTACT
                    </NavLink>
                    <NavLink to={`/career`} style={{ ...style.link }}>
                        CAREER
                    </NavLink>
                </Box>
            </Backdrop>
        </React.Fragment>
    )
};

export default Header;
