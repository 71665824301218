import * as React from "react";
import { Grid } from "@mui/material";

const Interior: React.FC = () => {

    return (
        <React.Fragment>
            <Grid container={true} sx={{ pt: 10, pb: 10, backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Grid item xs={12} md={1} />
                <Grid item={true} xs={12} md={4} sx={{ p: 4 }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis rhoncus quis arcu at bibendum. Vivamus eget laoreet augue. Aliquam libero ex, tincidunt eu metus maximus, cursus consectetur augue. Duis lacinia lorem sapien, vel sodales erat ullamcorper quis. Nunc ligula ante, porttitor non auctor ut, suscipit in ante. Pellentesque aliquam fermentum quam, vitae porttitor nulla condimentum ut. Sed nec nulla id mi accumsan lobortis. Phasellus vehicula diam nec arcu bibendum, in volutpat erat venenatis. Curabitur at arcu vel magna semper egestas eget id turpis. Morbi dignissim pellentesque orci, a dictum nisl feugiat vel.<br />
                    <br />
                    Nulla luctus urna non risus aliquam cursus. Etiam ornare, neque sed maximus posuere, sapien metus auctor dolor, id tempus libero nisl at sem. Mauris rutrum risus dolor, ut ultricies lacus gravida id.
                </Grid>
                <Grid item xs={12} md={1} />
                <Grid item={true} xs={12} md={6}>
                    <img src="/assets/images/under-construction.png" width="100%" />
                </Grid>
            </Grid>
            <Grid container={true} sx={{ pt: 10, pb: 10, backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Grid item={true} xs={12} md={6}>
                    <img src="/assets/images/under-construction.png" width="100%" />
                </Grid>
                <Grid item xs={12} md={1} />
                <Grid item={true} xs={12} md={4} sx={{ p: 4 }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis rhoncus quis arcu at bibendum. Vivamus eget laoreet augue. Aliquam libero ex, tincidunt eu metus maximus, cursus consectetur augue. Duis lacinia lorem sapien, vel sodales erat ullamcorper quis. Nunc ligula ante, porttitor non auctor ut, suscipit in ante. Pellentesque aliquam fermentum quam, vitae porttitor nulla condimentum ut. Sed nec nulla id mi accumsan lobortis. Phasellus vehicula diam nec arcu bibendum, in volutpat erat venenatis. Curabitur at arcu vel magna semper egestas eget id turpis. Morbi dignissim pellentesque orci, a dictum nisl feugiat vel.<br />
                    <br />
                    Nulla luctus urna non risus aliquam cursus. Etiam ornare, neque sed maximus posuere, sapien metus auctor dolor, id tempus libero nisl at sem. Mauris rutrum risus dolor, ut ultricies lacus gravida id.
                </Grid>
                <Grid item xs={12} md={1} />
            </Grid>
        </React.Fragment>
    )
};

export default Interior;
