import * as React from "react";

import {
    Box,
    Button, Container, FormControl,
    Grid,
    MenuItem, OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";

import Layout from "../common/Layout";
import Map from "../map/Map";
import Banner from "../common/Banner";

const SERVICES = [
    "Projectbouw",
    "Interieur ontwerp",
    "Onderhoud",
];

const style = {
    contactBlock: {
        width: "30%",
        padding: 2,
    },
    contactBlockItem: {
        backgroundColor: "white",
        border: `1px solid #e0e0e0`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: 4,
    },
    contactBlockTitle: {
        fontSize: "1.7em",
        fontWeight: "bold",
        margin: "30px 0 8px",
    },
    borderedTitle: {
        borderLeft: "3px solid #487490",
        paddingLeft: 1,
        marginBottom: 4,
    },
    contactFormContainer: {
        padding: 2,
        zIndex: 99,
        position: "relative",
    },
    contactForm: {
        margin: "64px 0",
        padding: "32px 64px",
        background: "white",
        border: "1px solid #e0e0e0",
    },
    contactFormButton: {
        backgroundColor: "#487490",
        float: "right",
        marginTop: 2,
    },
    inputBlock: {
        marginBottom: 4,
    },
    textBlock: {
        marginBottom: 4,
    },
    contactFormInput: {
        width: "100%",
    },
}

const Contact: React.FC = () => {

    const [service, setService] = React.useState<string>("");

    const handleChange = (event: SelectChangeEvent) => {
        setService(event.target.value as string);
    };

    return (
        <React.Fragment>
            {/*<Banner page="contact" />*/}

            <Grid container={true} sx={{ mt: 10, pt: 10, pb: 10 }} style={{ backgroundColor: "white", display: "flex", justifyContent: "center" }}>
                <Container>
                    <Grid container={true}>
                        <Grid item={true} xs={12} md={4} sx={style.contactBlock}>
                            <Box sx={style.contactBlockItem}>
                                <img src="/assets/images/contact-mail-icon-2.png" width={70} height={70} alt="E-mail" />
                                <Typography sx={style.contactBlockTitle}>E-mail</Typography>
                                <Typography sx={{ textAlign: "center", fontWeight: 100, minHeight: 50 }}>
                                    info@cooper-project.nl
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12} md={4} sx={style.contactBlock}>
                            <Box sx={style.contactBlockItem}>
                                <img src="/assets/images/contact-phone-icon-2.png" width={70} height={70} alt="Telefoon" />
                                <Typography sx={style.contactBlockTitle}>Telefoon</Typography>
                                <Typography sx={{ textAlign: "center", fontWeight: 100, minHeight: 50 }}>
                                    020 664 33 70
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12} md={4} sx={style.contactBlock}>
                            <Box sx={style.contactBlockItem}>
                                <img src="/assets/images/contact-office-icon-2.png" width={70} height={70} alt="Kantoor" />
                                <Typography sx={style.contactBlockTitle}>Kantoor</Typography>
                                <Typography sx={{ textAlign: "center", fontWeight: 100, minHeight: 50 }}>
                                    Tolstraat 132-134<br />
                                    1074 VL Amsterdam, Nederland
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>

            <Layout>
                <Grid container={true} sx={style.contactFormContainer}>
                    <Grid item={true} sx={style.contactForm}>
                        <Typography sx={{ ...style.contactBlockTitle, ...style.borderedTitle }}>
                            Contact
                        </Typography>
                        <Grid container={true} spacing={1}>
                            <Grid item={true} xs={12} md={6} sx={style.inputBlock}>
                                <TextField label="Naam" variant="outlined" sx={style.contactFormInput} />
                            </Grid>
                            <Grid item={true} xs={12} md={6} sx={style.inputBlock}>
                                <TextField label="E-mailadres" variant="outlined"
                                           sx={style.contactFormInput} />
                            </Grid>
                            <Grid item={true} xs={12} md={6} sx={style.inputBlock}>
                                <FormControl sx={style.contactFormInput}>
                                    <Select
                                        sx={style.contactFormInput}
                                        value={service}
                                        label="Dienst"
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Dienst" />}
                                    >
                                        <MenuItem value="-">Selecteer onderwerp</MenuItem>
                                        {SERVICES.map((service: string, index) => (
                                            <MenuItem key={index} value={service}>{service}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} md={6} sx={style.inputBlock}>
                                <TextField label="Telefoonnummer" variant="outlined"
                                           sx={style.contactFormInput} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <TextField multiline={true} rows={4} label="Bericht" variant="outlined"
                                           sx={style.contactFormInput} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Button sx={style.contactFormButton} variant="contained">Verstuur</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Layout>

            <Grid item={true} xs={12} style={{ marginTop: -200 }}>
                <div style={{ width: "100%", height: 550, zIndex: 9, background: "red", position: "relative" }}>
                    <Map center={[52.352851, 4.904420]} zoom={17} markerPosition={[52.352270, 4.904420]} />
                </div>
            </Grid>
        </React.Fragment>
    );
};

export default Contact;
