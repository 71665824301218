import * as React from "react";
import { Container } from "@mui/material";

type LayoutProps = {
    children?: React.ReactNode
};

const Layout: React.FC<LayoutProps> = ({ children }) => {

    return (
        <main>
            <Container fixed sx={{ paddingTop: 8 }}>
                {children}
            </Container>
        </main>
    )
};

export default Layout;
