import * as React from 'react';

import AppRouter from "./Router";
import Header from "./components/common/Header";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <React.Fragment>
        <BrowserRouter>
            <Header />
            <AppRouter/>
        </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
