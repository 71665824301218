import { createTheme } from "@mui/material";

interface ThemeVariables {
    primaryColor: string,
    secondaryColor: string,
    backgroundLight: string,
    backgroundDark: string,
    contrastBackground: string,
    outlinedButtonColor: string,
    dividerColor: string,
}

export const themeConfig: ThemeVariables = {
    primaryColor: "#48758d",
    secondaryColor: "#c27b7f",
    backgroundLight: "#FFFFFF",
    backgroundDark: "#FBFBFC",
    contrastBackground: "#F8F8F8",
    outlinedButtonColor: "#0DB3A3",
    dividerColor: "#E2E2E2",
};

declare module "@mui/material/styles/createPalette" {
    interface Palette {
        white: string;
        themeVariables: ThemeVariables;
        contrastBackground: string;
    }
    interface PaletteOptions {
        white: string;
        themeVariables: ThemeVariables;
        contrastBackground: string;
    }
}

export const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    height: "115px",
                    justifyContent: "center",
                    background: "rgba(255, 255, 255, 0.95)",
                    boxShadow: "none",
                },
            },
        },
        MuiImageListItemBar: {
            styleOverrides: {
                titleWrap: {
                    color: "#333",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 2,
                },
                focused: {
                    borderColor: themeConfig.primaryColor,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    color: "red @important",
                    "&:placeholder": {
                        color: "red @important",
                    }
                }
            }
        }
    },
});
