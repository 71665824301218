import * as React from "react";
import { Project } from "../../models/models";
import { Box, Grid, ImageList, ImageListItem, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import projectData from "../../data/projects.json";
import Map from "../map/Map";

import Lightbox from "yet-another-react-lightbox";
import { Slide } from "yet-another-react-lightbox/dist/types";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const style = {
    projectDetailContainer: {
        width: "100vw",
        minHeight: "87vh",
        padding: "0 10%",
        paddingTop: 20,
    },
    projectTitle: {
        fontSize: "25px",
    },
    projectIntro: {
        fontSize: "1.2em",
        marginBottom: 4,
    },
    projectDescription: {
        fontWeight: 200,
        whiteSpace: "break-spaces",
    },
    projectImage: {
        border: "1px solid #A1A1A1",
    },
}

const ProjectDetail: React.FC = () => {

    const [isLightboxOpen, setIsLightboxOpen] = React.useState<boolean>(false);
    const [lightboxInitialIndex, setLightboxInitialIndex] = React.useState<number>(0);

    const { ident } = useParams();
    const projects: Project[] | any[] = projectData;
    const project: Project = projects.filter((record: Project) => record.ident === ident)[0];

    const lightboxImages: Slide[] = project.images.map((image: string) => ({ src: image }));

    if (project === undefined) {
        return <div>loading</div>;
    }

    const openLightbox = (index: number): void => {
        setLightboxInitialIndex(index);
        setIsLightboxOpen(true);
    };

    return (
        <Box sx={style.projectDetailContainer}>

            <Grid container={true}>
                <Grid item={true} xs={12}>
                    <Typography sx={style.projectTitle}>
                        {project.title}
                    </Typography>
                </Grid>
                <Grid item={true} xs={12} md={7} sx={{ pt: 6, pb: 10, pr: 5, minHeight: 300 }}>
                    <Typography sx={style.projectIntro}>
                        <i>{`"${project.intro}"`}</i>
                    </Typography>
                    <Typography sx={style.projectDescription}>
                        {project.description}
                    </Typography>
                </Grid>
                <Grid item={true} xs={12} md={5} sx={{ mb: 10, display: "flex", alignItems: "center", justifyContent: "center", background: "#f8f8f8", minHeight: 300 }}>
                    <Map center={project.latLng} zoom={15} markerPosition={project.latLng} />
                </Grid>
            </Grid>

            <ImageList variant="masonry" cols={3} gap={30}>
                {project.images.map((image: string, index) => (
                    <ImageListItem key={index}>
                        <img
                            style={{ ...style.projectImage, cursor: "pointer" }}
                            src={`${image}?w=248&fit=crop&auto=format`}
                            srcSet={`${image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={image}
                            loading="lazy"
                            onClick={() => openLightbox(index)}
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            <Lightbox
                open={isLightboxOpen}
                close={() => setIsLightboxOpen(false)}
                index={lightboxInitialIndex}
                slides={lightboxImages}
                plugins={[Thumbnails]}
                thumbnails={{
                    position: "start"
                }}
            />
        </Box>
    )
};

export default ProjectDetail;
