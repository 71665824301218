import * as React from "react";
import { Box } from "@mui/material";

const HeroBanner: React.FC = () => {

    return(
        <Box sx={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            HOMEPAGE
        </Box>
    )
};

export default HeroBanner;
